@font-face {
    font-family: "JetBrains Mono";
    src: url("https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Bold.woff2") format("woff2"),
        url("https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "JetBrains Mono";
    src: url("https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Regular.woff2")
            format("woff2"),
        url("https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "JetBrains Mono";
    src: url("https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Thin.woff2") format("woff2"),
        url("https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Thin.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.slick-dots {
    bottom: -20px !important;
}

.slick-list {
    transition: height 0.4s ease-out;
}

body {
    color: rgb(36, 41, 46);
    display: flex;
    justify-content: center;
}

html {
    background: white;
    overflow-x: hidden;
    width: 100vw;
}

.page-container {
    width: 75vw;
    margin-bottom: 50px;
    max-width: 1200px;
}

img {
    max-width: 100%;
}

iframe {
    width: 100%;
    height: 37.5vw;
    color: white;
    margin-top: 0.5rem;
}

nav {
    margin-top: 40px;
    margin-bottom: 50px;
}

nav .subheaders a {
    font-size: 33px;
}

.code-nav li {
    margin-right: 1.8rem;
}

.video-nav li {
    margin-right: 1.6rem;
}

nav .subheaders,
footer ul {
    padding: 0px;
    list-style: none;
}

a,
a:hover,
a:active {
    text-decoration: none;
}

a {
    color: rgb(36, 41, 46);
}

p {
    font-size: 20px;
    line-height: 1.15;
    text-align: justify;
    font-weight: 300;
    font-family: "Cairo", sans-serif;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: min(6vw, 3.25rem);
    margin-top: 1rem;
    margin-bottom: -0.25rem;
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
}

h3 {
    font-size: min(4vw, 2.25rem);
    margin-top: 1.5rem;
    margin-bottom: -0.25rem;
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
}

footer {
    margin-top: 24px;
    border-top: 2px solid #d5d5d5;
}

footer img:hover {
    opacity: 1;
}

.red {
    color: #d73a49;
}

.green {
    color: rgb(34, 134, 58);
}

.blue {
    color: #005cc5;
}

.purple {
    color: rgb(111, 66, 193);
}

.orange {
    color: rgb(227, 98, 9);
}

.yellow {
    color: rgb(219, 171, 9);
}

.pink {
    color: rgb(236, 108, 185);
}

.black {
    color: rgb(36, 41, 46);
}

.white {
    color: white;
}

.gray {
    color: rgb(149, 157, 165);
}

.bold {
    font-weight: bold;
}

.mono {
    font-family: "JetBrains Mono", monospace;
}

.weight-400 {
    font-weight: 400;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

.side-image-left {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 1rem;
    padding-top: 0.5rem;
    float: left;
}

.side-image-right {
    width: 48%;
    margin-left: 4%;
    margin-bottom: 1rem;
    padding-top: 0.5rem;
    float: right;
}

.no-underline {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: max-content;
}

.hidden {
    display: none;
}

.label {
    font-size: min(max(0.9rem, 1.8vw), 1.25rem);
    text-align: center;
    margin-top: 0.5vw;
    font-style: italic;
    font-weight: 200;
    margin-bottom: min(2vw, 2rem);
}

.closing-brace {
    font-size: 40px;
    margin: 15px 0px;
}

.link-container {
    border-top: 2px solid #d5d5d5;
    margin-top: -0.175rem;
    padding-top: 0.75rem;
    margin-bottom: 2vw;
}

.section-container {
    border-top: 2px solid #d5d5d5;
    padding: 0;
    overflow: hidden;
}

.link-item {
    display: table-cell;
    vertical-align: middle;
    width: 15%;
}

.link-item a {
    height: 100%;
    vertical-align: middle;
}

.footer-links {
    max-width: 350px;
}

.footer-link a svg {
    opacity: 0.35;
    transition: all 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
    vertical-align: middle;
    filter: saturate(0);
    max-width: 8vw;
}

.footer-link a svg:hover {
    opacity: 1;
    transform: scale(1.15);
    filter: saturate(1);
}

.footer-copyright {
    font-size: 18px;
}

.home-image {
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 40vw;
}

.menu-container {
    max-width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: min(3%, 2rem);
}

.menu-container a {
    flex-basis: 40%;
    margin-bottom: min(3%, 2rem);
    flex-grow: 1;
}

.photo-card,
.code-card,
.video-card {
    width: 100%;
    height: 100%;
    filter: none;
    position: relative;
}

.photo-card img,
.code-card img,
.video-card img {
    filter: brightness(70%);
}

.home-link-card {
    width: 100%;
    height: 10vw;
    max-height: 130px !important;
}

.home-link-card .card-title h1 {
    font-size: min(4vw, 3rem);
}

.photo-set-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    user-select: none;
}

.carousel-container {
    /* border-bottom: 1px solid #d5d5d5; */
    padding-bottom: 0.5vw;
    text-align: center;
    display: flex;
    align-items: center;
    user-select: none;
}

.slick-left,
.slick-right {
    display: none !important;
    user-select: none;
    padding-bottom: 6rem;
    margin-top: 0;
    color: rgb(36, 41, 46);
    transition: color 0.25s;
    font-size: min(4vw, 2.5rem);
}

.slick-left:hover,
.slick-right:hover {
    cursor: pointer;
    /* color: rgb(36, 41, 46); */
    color: rgb(149, 157, 165);
}

.slick-slide {
    user-select: none;
}

.photo {
    user-select: none;
    max-width: min(1200px, 75vw);
    max-height: 50vw;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transition: opacity 1s;
    -webkit-touch-callout: default;
    -webkit-user-select: default;
    -khtml-user-select: default;
    -moz-user-select: default;
    -ms-user-select: default;
    user-select: default;
}

.gray-hover {
    transition: color 0.25s;
}

.gray-hover:hover {
    color: rgb(149, 157, 165);
}

.settings-table {
    display: none;
    width: 100%;
    justify-content: space-between;
    margin: auto;
}

.settings-table p {
    text-align: center;
    color: rgb(106, 115, 125);
    /* color: rgb(149, 157, 165); */
}

.settings-table-titled {
    flex-wrap: wrap;
    display: flex;
}

.settings-table-titled > p:first-child {
    flex: 0 0 100%;
    color: rgb(36, 41, 46);
    font-weight: 300;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    font-style: italic;
}

.settings-table-titled > p:not(:first-child) {
    margin-top: 0;
    font-weight: 200;
    display: none;
}

.slick-dots {
    display: none !important;
}

.description {
    margin-top: -2rem;
}

@media (hover: hover) and (pointer: fine) {
    a:hover {
        text-decoration: underline;
    }

    .no-underline:hover {
        text-decoration: none;
    }

    .slick-left,
    .slick-right {
        display: inline-block !important;
    }

    .photo-set-container {
        width: 100%;
    }

    .settings-table {
        width: 100%;
        justify-content: center;
        gap: 5%;
    }

    .slick-dots {
        display: block !important;
    }

    .description {
        margin-top: 1.25rem;
    }

    .settings-table-titled > p:first-child {
        margin-bottom: 0.5rem;
        margin-top: 1.5rem;
    }
}

@media (max-width: 900px) {
    nav h1 {
        font-size: 50px;
    }

    nav {
        margin: 30px 0px 40px;
    }

    nav .subheaders a {
        font-size: 25px;
    }

    p {
        font-size: 16px;
    }

    .link-container br {
        display: none;
    }

    .home-link-card {
        height: 80px;
        margin-bottom: 8px;
    }

    .home-link-card .card-title h1 {
        font-size: 25px;
    }

    .closing-brace {
        margin: 5px 0px;
    }

    .footer-copyright {
        text-align: center;
        font-size: 15px;
        padding-right: 25px;
    }

    .footer-links {
        max-width: 450px;
        padding-left: 15px;
    }

    .code-nav li {
        margin-right: 0;
    }

    .video-nav li {
        margin-right: 0;
    }

    iframe {
        height: 40vw;
    }

    .photo {
        max-width: 80vw;
        max-height: 52vw;
    }
}

@media (max-width: 600px) {
    .page-container {
        margin-bottom: 25px;
        width: 85vw;
    }

    .photo {
        max-width: 85vw;
        max-height: 55.25vw;
    }

    @media (max-aspect-ratio: 3/4) {
        .photo {
            max-height: 85vh;
        }
    }

    nav h1 {
        font-size: 8.5vw;
    }

    nav {
        margin: 10px 0px 20px;
    }

    nav .subheaders a {
        font-size: 4.4vw;
        margin-top: 5px;
    }

    p {
        font-size: 15px;
    }

    .home-link-card {
        height: 50px;
        margin-bottom: 5px;
    }

    .home-link-card .card-title h1 {
        font-size: 20px;
    }

    .closing-brace {
        margin: 5px 0px;
    }

    .footer-copyright {
        font-size: 14px;
        margin: 10px 0px 0px;
    }

    .code-nav li {
        margin-right: 0;
    }

    .video-nav li {
        margin-right: 0;
    }

    .link-item img {
        max-width: min(55%, 80px);
        max-height: min(55%, 80px);
    }

    h3 {
        font-size: 1.5rem;
    }

    .label {
        margin-bottom: 1.25rem;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes subtleBreathing {
    0% {
        opacity: 0.0875;
    }
    50% {
        opacity: 0.175;
    }
    100% {
        opacity: 0.0875;
    }
}
