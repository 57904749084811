#multi-photo-page {
    width: 80vw !important;
    max-width: none !important;
}

.multi-photo {
    max-width: 80vw;
    max-height: 38vw;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
}

@media (min-aspect-ratio: 2/1) and (min-width: 1000px) {
    #multi-photo-page {
        width: 70vw !important;
        max-width: none !important;
    }

    .multi-photo {
        max-width: 70vw;
        max-height: 19.5vw;
    }
}

@media (max-aspect-ratio: 3/4) {
    .multi-photo {
        max-width: 85vw;
        max-height: 85vh;
    }
}

@media (max-width: 1000px) {
    .multi-photo {
        padding-left: 0.67rem;
        padding-right: 0.67rem;
    }
}
